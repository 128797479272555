"use client"
import { ADAPTER_EVENTS, CustomChainConfig, WALLET_ADAPTER_TYPE, CHAIN_NAMESPACES, WEB3AUTH_NETWORK, IProvider, UserAuthInfo } from "@web3auth/base";
import type LOGIN_PROVIDER_TYPE from "@toruslabs/openlogin";
// import type OPENLOGIN_NETWORK_TYPE from "@toruslabs/openlogin";
import { OpenLoginOptions  } from "@toruslabs/openlogin-utils";
import { Web3AuthCore, Web3AuthCoreOptions } from "@web3auth/core";
import { Web3Auth  } from "@web3auth/modal";
import { getDefaultExternalAdapters } from "@web3auth/default-solana-adapter"; // All default Solana Adapters
import { SolanaPrivateKeyProvider } from "@web3auth/solana-provider";
import { getAuthHeadersAndBody } from '@/helpers/web3auth/verifyToken';
import {Inputs} from "@/components/forms/userRegForm";
import { LOGIN_PROVIDER } from "@web3auth/openlogin-adapter";
import {WALLET_ADAPTERS} from "@web3auth/base";
// import * as dotenv from "dotenv";
// dotenv.config({
//   path: ".env.local",
// });
import { toast } from "react-toastify";
import {
  createContext,
  FunctionComponent,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
  useCallback
} from "react";
import {
  SOL_CHAIN_CONFIG,
  CHAIN_CONFIG_TYPE,
  SOL_LOCAL_CHAIN_CONFIG,
} from "@/context/Web3AuthChainConfigs";
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";
// import {PhantomAdapter} from "@web3auth/phantom-adapter";
import { WEB3AUTH_NETWORK_TYPE } from "@/context/Web3AuthNetworkType";
import { init } from "next/dist/compiled/webpack/webpack";
import { SolanaWallet } from "@web3auth/solana-provider";
import { GoTrueAdminApi } from "@supabase/supabase-js";
import { Connection, PublicKey, SystemProgram, TransactionMessage, VersionedTransaction } from "@solana/web3.js";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
// import { findBoxOfficePda, findMetadataMapPda, executeTx, mintCnftIx, } from "@3-Tree-Labs/box-office-v2/dist/esm/box-office-v2-client";
import * as anchor from "@coral-xyz/anchor";
import { ConsoleLogWriter } from "drizzle-orm";
import { createUmi } from '@metaplex-foundation/umi-bundle-defaults';
import { Umi, publicKey, Signer, SolAmount, sol, transactionBuilder} from '@metaplex-foundation/umi';
import { walletAdapterIdentity, WalletAdapter,  } from '@metaplex-foundation/umi-signer-wallet-adapters';
import { transferSol, addMemo, Mint,
  Token,
  fetchMint,
  fetchToken,
  findAssociatedTokenPda } from '@metaplex-foundation/mpl-toolbox';
import {Program, AnchorProvider, setProvider} from '@project-serum/anchor';
import {fromWeb3JsInstruction, fromWeb3JsTransaction, fromWeb3JsMessage} from '@metaplex-foundation/umi-web3js-adapters';
import  Web3AuthWalletAdapter  from '@/helpers/web3auth/wallet';
// import { getBoxOfficeProgram } from "@/helpers/solana/programManager";
import {mintCnft} from "@3-Tree-Labs/box-office-v3/dist/esm/lib/process-mint";


export interface IWeb3AuthContext {
  web3Auth: Web3Auth | null;
  provider: SolanaPrivateKeyProvider | unknown;
  isLoading: boolean;
  isLoggedIn: boolean;

  userData: any;
  connection: any | null;
  login: (
  ) => Promise<any>;
  logout: () => Promise<void>;
  reconnect: () => Promise<AuthData | null>;
  getUserInfo: () => Promise<AuthData | null>;
  authenticateUser: () => Promise<UserAuthInfo | null>;
  getAccounts: () => Promise<string[]>;
  getBalance: () => Promise<SolAmount | null>;
  sendTransaction: () => Promise<string>;
  signMessage: () => Promise<string>;
  registerUser: (data: Inputs) => Promise<{ status: number; message: string; }>;
  updateUserData: (data: Partial<AuthData>) => void;
  mintCnftUmi: (params: MintCnftParams) => Promise<string>;
}

export interface W3AuthUser {
  aggregateVerifier: string;
  idToken: string;
  profileImage: string;
  verifier: string;
  verifierId: string;
  typeOfLogin: string;
  oAuthIdToken: string;
  oAuthAccessToken: string;
}

export interface CustomUserData {
  id: string;
  first_name: string;
  last_name: string;
  email_address: string;
  phone_number: string;
  image_url: string;
  access_level: string;
  sandbox_expiry: Date | null;
}

export interface AuthData {
  web3AuthUser: any;
  customUserData: CustomUserData;
  provider: IWalletProvider | any | null;
  wallet: SolanaWallet | null;
  signer: Signer | null;
  registered: boolean;
  pk: any;
}

export interface MintCnftParams {
  uri: string;
  recipient: string;
}


export const Web3AuthContext = createContext<IWeb3AuthContext>({
  web3Auth: null,
  provider: null,
  isLoading: false,
  isLoggedIn: false,
  userData: null,
  connection: null,
  login: async () => {},
  logout: async () => {},
  reconnect : async () => null,
  getUserInfo: async () => null,
  authenticateUser: async () => null,
  getAccounts: async () => [],
  getBalance: async () => null,
  sendTransaction: async () => "",
  signMessage: async () => "",
  registerUser: async (data: Inputs) => {return {status: 500, message: "Internal Server Error"}},
  updateUserData: (data: Partial<AuthData>) => {},
  mintCnftUmi: async (params: MintCnftParams) => "",
});

export function useWeb3Authentication() {
  const context = useContext(Web3AuthContext);
  if (context === undefined) {
    throw new Error('useWeb3Authentication must be used within a Web3AuthProvider');
  }
  return context;
}

type MakeOptional<Type, Key extends keyof Type> = Omit<Type, Key> & Partial<Pick<Type, Key>>;


interface IWeb3AuthProps {
  children?: ReactNode;
  // web3AuthNetwork?: WEB3AUTH_NETWORK_TYPE;
  // chain?: CHAIN_CONFIG_TYPE;
}

// const clientId = process.env.WEB3AUTH_CLIENTID;
const clientId = "BIvW4XYNdQ0C5NMfBgfGD8dhJ74DcjvKmc7w955TpqIk43FJUN4G99pPnuy3JDoOIV0jyC71g8xZFFabi02euic";
// console.log("Client ID", clientId);
          // "BA6Kq8xVYzJJ8_ddtsPsVYUTVKBu-ibzsCsQONdW-V3Vp0LZ-PTS8ak4VlQ6d1oKwdtXKHZk4RL08dXyf-8Ab6w";

export interface IWalletProvider {
  getAccounts: () => Promise<any>;
  getPK: () => Promise<any>;
}

export const Web3AuthProvider: FunctionComponent<IWeb3AuthProps> = ({
  children,
}) => {
  const [web3Auth, setWeb3Auth] = useState<Web3Auth | null>(null);
  const [provider, setProvider] = useState<IProvider | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState<any | null>(null);
  const [connection, setConnection] = useState<any | null>(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  // const [boxOfficeProgram, setBoxOfficeProgram] = useState<Program | null>(null);
  // const metadataMapPda = findMetadataMapPda(program, env.process.tt_key, )
  // const boxofficePda = findBoxOfficePda(program, process.env.tt_key, process.env.tt_key);
  // const metadataMapPda = new PublicKey("AS1frqj2DnxzRK42dZ7LpK7WC2QA8VUm6agKJ8fd2EGW")
  const boxofficePda = publicKey("EW7WT2Vq4j72kUbmjQJRUo8A8Tfc4mhZp31J7yNXb4ZF");

  const setUserDataState = (newUserData: any) => {
    setUserData(newUserData);
    localStorage.setItem('userData', JSON.stringify(newUserData));
  }


  const [isInitializing, setIsInitializing] = useState(false);
  
  const initializeConnection = useCallback(async () => {
    if (isInitializing || !userData?.wallet) return;
    
    setIsInitializing(true);
    console.log("Initializing connection");
    
    try {
      const connectionConfig = await userData.wallet.request({
        method: "solana_provider_config",
        params: [],
      });
      
      const walletAdapter = new Web3AuthWalletAdapter(userData.wallet);
      const signer = walletAdapterIdentity(walletAdapter);
      console.log("Signer", signer);
      
      let connUmi;
      try {
        connUmi =  createUmi(connectionConfig.rpcTarget).use(signer);
      } catch (error) {
        console.error("Error creating Umi", error);
        setIsInitializing(false);
        return;
      }
      
      if (!connUmi) {
        console.error("Connection not initialized");
        setIsInitializing(false);
        return;
      }
      
      setConnection(connUmi);
      
      setUserDataState((prevUserData:any) => ({
        ...prevUserData,
        signer: connUmi.payer,
      }));
    } catch (error) {
      console.error("Error initializing connection:", error);
    } finally {
      setIsInitializing(false);
    }
  }, [userData?.wallet, isInitializing]);
  
  useEffect(() => {
    if (userData?.wallet && !connection && !isInitializing) {
      initializeConnection();
    }
  }, [userData?.wallet, connection, isInitializing, initializeConnection]);

  const logout = useCallback( async () => {
    if (!web3Auth) {
      console.log("web3auth not initialized yet");
      return;
    }
    await web3Auth.logout();
    setProvider(null);
    setUserData(null);
    localStorage.removeItem('userData');
    try {
      const response = await fetch('/api/v1/users/logoff', {
        method: 'POST',
        credentials: 'include', // Important for including cookies
      });
  
      if (!response.ok) {
        throw new Error('Logout failed');
      }
  
      sessionStorage.clear();
  
      window.location.href = '/';
    } catch (error) {
      console.error('Logout error:', error);
      return;
      }
  }, [web3Auth]);

  const authenticateUser = async () => {
    if (!web3Auth) {
      console.log("web3auth not initialized yet");
      return null;
    }
    const idToken = await web3Auth.authenticateUser();
    return idToken;
  };


  const subscribeAuthEvents = useCallback(async (web3auth: Web3Auth) => {
    // Can subscribe to all ADAPTER_EVENTS and LOGIN_MODAL_EVENTS

    web3auth.on(ADAPTER_EVENTS.CONNECTING, () => {
      console.log("connecting");
    });

    web3auth.on(ADAPTER_EVENTS.CONNECTED, () => {
      console.log("connected");
    });

    web3auth.on(ADAPTER_EVENTS.DISCONNECTED, () => {
      console.log("disconnected");
      // setUserDataState(null);
      
    });

    web3auth.on(ADAPTER_EVENTS.ERRORED, (error: unknown) => {
      console.error("some error or user has cancelled login request", error);
    });
  }, []);

  const initializeWeb3Auth = useCallback(async () => {
    try {
      setIsLoading(true);

      const solanaPrivateKeyProvider = new SolanaPrivateKeyProvider({
        config: { chainConfig: SOL_CHAIN_CONFIG }
      });

      const web3AuthInstance = new Web3Auth({
        clientId,
        web3AuthNetwork: 'sapphire_devnet',
        privateKeyProvider: solanaPrivateKeyProvider
        
      });

      const openloginAdapter = new OpenloginAdapter({
        privateKeyProvider:solanaPrivateKeyProvider,
        adapterSettings: {
          uxMode: "popup",
          loginConfig: {
            google: {
              name: "Google",
              verifier: "tick3ttree-google",
              typeOfLogin: "google",
              clientId: "802034975459-vdg4cg6nn09bbct78ulcijstkm1cnduf.apps.googleusercontent.com",
            },
          },
        },
      });

      web3AuthInstance.configureAdapter(openloginAdapter);


      setWeb3Auth(web3AuthInstance);
      await web3AuthInstance.initModal({
        modalConfig: {
              [WALLET_ADAPTERS.OPENLOGIN]: {
                label: "openlogin",

                loginMethods: {
                  google: {
                    name: "Google",
                    // logoDark: "url to your custom logo which will shown in dark mode",
                    showOnModal: true,
                  },
                  facebook: {
                    name: "Facebook",
                    // it will hide the facebook option from the Web3Auth modal.
                    showOnModal: false,
                  },
                  // google, facebook, twitter, reddit, discord, twitch, apple, line, github, kakao, linkedin, weibo, wechat
                  twitter: {
                    name: "Twitter",
                    showOnModal: false,
                  },
                  reddit: {
                    name: "Reddit",
                    showOnModal: false,
                  },
                  discord: {
                    name: "Discord",
                    showOnModal: false,
                  },
                  twitch: {
                    name: "Twitch",

                    showOnModal: false,
                  },
                  apple: {
                    name: "Apple",
                    showOnModal: false,
                  },
                  line: {
                    name: "Line",
                    showOnModal: false,
                  },
                  github: {
                    name: "Github",
                    showOnModal: false,
                  },
                  kakao: {
                    name: "Kakao",
                    showOnModal: false,
                  },
                  linkedin: {
                    name: "Linkedin",
                    showOnModal: false,
                  },
                  weibo: {
                    name: "Weibo",
                    showOnModal: false,
                  },
                  wechat: {
                    name: "Wechat",
                    showOnModal: false,
                  },
                  farcaster: {
                    name: "Farcaster",
                    showOnModal: false,
                  }

                },
                // setting it to false will hide all social login methods from modal.
              },
              // [WALLET_ADAPTERS.PHANTOM]: {
              //   label: "phantom",
              //   loginMethods: {
              //     phantom: {
              //       name: "phantom login",
              //       // logoDark: "url to your custom logo which will shown in dark mode",
              //     },
              //   },
              },
            },);

    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (web3Auth) 
      subscribeAuthEvents(web3Auth);

  }, [web3Auth, subscribeAuthEvents]);

  useEffect(() => {
    initializeWeb3Auth();
  }, [initializeWeb3Auth]);



  const getUserInfo = useCallback(async (): Promise<AuthData | null> => {
    if (web3Auth) {
      return userData;
    }
    return null;
  }, [userData, web3Auth]);

  const login = useCallback(async () => {
    setIsLoading(true);
    let userWallet;
    let web3authProvider;
    if (!web3Auth) {
      return;
    }

    try{
      web3authProvider = await web3Auth.connect();
      if (!web3authProvider) {
        return;
       }
       setProvider(web3authProvider);
      
    } catch (error) {
      // Error handling
    }
    if (!web3authProvider) {
      return;
    }

    try{ 
      userWallet = new SolanaWallet(web3authProvider);
    }
    catch (error) {
      // Error handling
    }

    try {
      if (web3Auth.connected){
        const web3AuthUserData = await web3Auth.getUserInfo();
        try {
          const { headers, body: authBody } = await getAuthHeadersAndBody({...web3AuthUserData,userWallet: userWallet}, authenticateUser, web3Auth);
          const response = await fetch("/api/v1/users/check", {
            method: "POST",
            headers: {...headers, 
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({...authBody,
              userId: web3AuthUserData.verifierId,
              
            }),
          });



          const customUserData = await response.json();
          // console.log("Custom User Data", customUserData)
          // toast.info(`Custom User Data ${JSON.stringify(customUserData.user)}`, {
          //   position: "top-right",
          //   autoClose: 5000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          // });
          
          let newUserData;
          if (response.ok) {
            if (customUserData.exists) {
              console.log("User Exists");
              newUserData = {
                web3AuthUser: web3AuthUserData,
                customUserData: customUserData.user,
                provider: web3authProvider,
                wallet: userWallet,
                registered: true,
                sandbox_expiry: customUserData.user.sandbox_expiry,
                access_level: customUserData.user.access_level,
                pk: null,
              }
            } else {
              const response = await fetch("/api/v1/users/register", {
                method: "POST",
              headers: headers,
              body: JSON.stringify({...authBody, 
                userId: web3AuthUserData.verifierId,
                solana_address: (await userWallet?.requestAccounts())![0],
                image_url: web3AuthUserData.profileImage,
                name: web3AuthUserData.name,
                type_of_login: web3AuthUserData.typeOfLogin,

              }),
            });
            const customUserData = await response.json();
            newUserData = {
              web3AuthUser: web3AuthUserData,
              customUserData: customUserData,
              provider: web3authProvider,
              wallet: userWallet,
              registered: true,
              pk: null,
              sandbox_expiry: customUserData.user.sandbox_expiry,
              access_level: customUserData.user.access_level,
            }
          }
          
          setUserData(newUserData);


          return newUserData;
          } else {
            console.error("Error syncing user data:", response.statusText);
            return null;
          }
        } catch (error) {
          console.error("Error syncing user data:", error);
          return null;
        }
      }
    } catch (error) {
      setIsLoggedIn(false);
      console.log("error", error);
    } finally {
      setIsLoggedIn(true);
      setIsLoading(false);
    }
  }, [web3Auth, authenticateUser, userData]);


  const reconnect = useCallback(async () => {
    console.log("Reconnecting...", { userData, web3Auth, connected: web3Auth?.connected, provider: web3Auth?.provider });
  
    if (!web3Auth) {
      console.log("Web3Auth not initialized");
      return null;
    }
  
    try {
      let provider;
      if (!web3Auth.connected) {
         provider = await web3Auth.connect();
      }
      console.log("Provider", provider);
      if (!provider) {
        console.log("Provider not initialized");
        return null;
      }
      
  
      const web3AuthUserData = await web3Auth.getUserInfo();
      if (!web3Auth.provider) {
        console.log("Provider not initialized");
        return null
      }
      const userWallet = new SolanaWallet(web3Auth.provider);
  
      const storedUserData = localStorage.getItem('userData');
      console.log("Stored User Data", storedUserData);
      let newUserData: AuthData;
  
      if (storedUserData && storedUserData !== "undefined") {
        console.log("Reconnecting with stored user data");
        
        const parsedUserData = JSON.parse(storedUserData) as AuthData;
        console.log("Parsed User Data", parsedUserData);
        newUserData = {
          ...parsedUserData,
          web3AuthUser: web3AuthUserData,
          wallet: userWallet,
          provider: web3Auth.provider,
        };
        console.log("New User Data", newUserData);
      } else {
        // Fetch custom user data from your API
        console.log("Reconnecting with new user data");
        const { headers, body: authBody } = await getAuthHeadersAndBody({ ...web3AuthUserData, userWallet:userWallet }, authenticateUser, web3Auth);

        // log out for token removal 
        // try {
        //   const response = await fetch('/api/v1/users/logoff', {
        //     method: 'POST',
        //     credentials: 'include', // Important for including cookies
        //   });
      
        //   if (!response.ok) {
        //     throw new Error('Logout failed');
        //   }
      
        //   sessionStorage.clear();
        // } catch (error) {
        //   console.error("Error logging off:", error);
        // }

        const response = await fetch("/api/v1/users/check", {
          method: "POST",
          headers: headers,
          body: JSON.stringify({...authBody, 
            userId: web3AuthUserData.verifierId,}
          ),
        });
  
        if (!response.ok) {
          throw new Error(`API request failed with status ${response.status}`);
        }
  
        const customUserData = await response.json();
        
        newUserData = {
          web3AuthUser: web3AuthUserData,
          customUserData: customUserData,
          provider: web3Auth.provider,
          wallet: userWallet,
          registered: customUserData.exists,
          pk: null,
          // signer: userWallet.signer, // Ensure this is included if needed
          signer: null,
        };
      }
  
      setUserDataState(newUserData);
  
      console.log("Reconnection successful", newUserData);
      return newUserData;
  
    } catch (error) {
      console.error("Reconnection failed:", error);
      return null;
    }
  }, [web3Auth, userData]);

const registerUser = useCallback(async (data: Inputs ) => {
  setIsLoading(true);

  // Make an API call to register the user
  const { headers, body: authBody } = await getAuthHeadersAndBody(
    userData.web3AuthUser,
    authenticateUser,
    web3Auth
  );

  const requestBody = {
    ...authBody,
    ...data,
    solana_address: (await getAccounts())[0],
    userId: userData.web3AuthUser.verifierId
  };

  const response = await fetch("/api/v1/users/register", {
    method: "POST",
    headers: headers,
    body: JSON.stringify(requestBody),
  })
    .catch((err) => {
      console.log(err);
      // toast.error("Error During Registration");
    });

  if (response) {
    if (response.status == 200) {
    const result = await response.json();
    console.log(result);
      setUserData({
        ...userData,
        customUserData: data,
        registered: true,
      });
    // toast.success("User Registered Successfully");
    setIsLoading(false);
    return {status: response.status, message: "User Registered Successfully"};
  }
  if (response.status == 400) {
    setIsLoading(false);
    return {status: response.status, message: "User Already Exists"};
  }
  if (response.status == 500) {
    setIsLoading(false);
    return {status: response.status, message: "Internal Server Error"};
}
  }
  setIsLoading(false);
  return {status: 500, message: "Internal Server Error"};

}, [userData, authenticateUser, web3Auth]);


  const getAccounts = async (): Promise<string[]> => {
    if (!userData?.wallet) {
      console.log("Wallet not initialized yet");
      return [];
    }
    console.log("Wallet", userData.wallet, "Provider", userData.provider);
    try {
      const accounts = await userData.wallet.requestAccounts();
      return accounts;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const getBalance = async (): Promise<SolAmount | null> => {
    if (!userData?.wallet || !connection) {
      console.log("Wallet not initialized yet");
      return null;
    }
    try {
      // const connectionConfig = await userData.wallet.request<string[], CustomChainConfig>({
      //   method: "solana_provider_config",
      //   params: [],
      // });
      // const conn = new Connection(connectionConfig.rpcTarget);

      const accounts = await userData.wallet.requestAccounts();
      const balance = await connection.rpc.getBalance(publicKey(accounts[0]));
      console.log("Balance", balance.basisPoints);

      let airdropSig = await connection.rpc.airdrop(publicKey(accounts[0]), sol(2));
      // conn.confirmTransaction(airdropSig);

      const balance2 = await connection.rpc.getBalance(publicKey(accounts[0]));

      console.log("Balance After Airdrop", balance2.basisPoints);
      console.log("Balance", balance.basisPoints);
      return balance2;
    } catch (error) {
      console.error(error);
      return null; 
    }
  }
  
  const sendTransaction = async (): Promise<string> => {
    if (!userData?.wallet || !connection) {
      console.log("Wallet not initialized yet");
      return "";
    }
    try {
      const accounts = await userData.wallet.requestAccounts();
      // const connectionConfig = await userData.wallet.request<string[], CustomChainConfig>({
      //   method: "solana_provider_config",
      //   params: [],
      // });
      // const connection = new Connection(connectionConfig.rpcTarget);

      // console.log("Accounts", accounts);
      // console.log("Connection", connection);
      // console.log("Connection Config", connectionConfig)

      const block = await connection.rpc.getLatestBlockhash({commitment:"finalized"});

      try {
      const confirmResult = await transferSol(connection, {
        source: userData.signer as any,
        destination: publicKey(accounts[0]),
        amount: sol(0.001),
    }).sendAndConfirm(connection) 
    console.log("Confirm Result", confirmResult);
    return "Success";

      }
      catch (error) {
        console.error(error);
        return "Failure";
      }


      // try {
      //   const signature = await connection.rpc.sendTransaction(transaction);
      //   const confirmResult = await connection.rpc.confirmTransaction(signature, {
      //     strategy: {type: 'blockhash', ...(await connection.rpc.getLatestBlockhash())},
      //   });
      //   return "Success";
      // } catch (error) {
      //   console.error(error);
      //   return "Failure";
      // }
    
    } catch (error) {
      console.error(error);
      return "Failure";
    }
  };

  
  const signMessage = async (): Promise<string> => {
    if (!userData?.wallet) {
      console.log("Wallet not initialized yet");
      return "";
    }
    // try {
    //   const msg = Buffer.from("Test Signing Message ", "utf8");
    //   const res = await userData.wallet.signMessage(msg);
    //   return res.toString();
    // } catch (error) {
    //   console.error(error);
    //   return "";
    // }

    try {
      const message = "Hello, Solana!";
      console.log(connection?.payer)
      const signature = await connection?.payer.signMessage(Buffer.from(message));
      if (!signature) {
        console.error("Failed to sign message");
        return "";
      }
      return signature.toString();
    } catch (error) {
      console.error(error);
      return "";
    }
  };
  
  const getPrivateKey = async (): Promise<string> => {
    if (!userData?.provider) {
      console.log("Provider not initialized yet");
      return "";
    }
    try {
      const privateKey = await userData.provider.request({
        method: "solanaPrivateKey",
      });
      return privateKey as string;
    } catch (error) {
      console.error(error);
      return "";
    }
  };

  const updateUserData = (data: Partial<AuthData>) => {
    setUserData((prevData:any) => {
      return {
        ...prevData,
        ...data,
      };
    });
  };

  const mintCnftUmi = async (params: MintCnftParams) => {
    if (!userData?.wallet || !connection) {
      throw new Error("Wallet not initialized");
    }
    const recipient = publicKey(params.recipient);
    console.log("Recipient", recipient);
    console.log("Box Office PDA", publicKey(boxofficePda));
    console.log("Signer", connection.payer);


    try {
      const sig = await mintCnft(publicKey(boxofficePda), connection, connection.payer, publicKey(recipient), [1, 6, 1, 0, 0, 0, 0, 0, 0, 0], null, params.uri);
      console.log("Mint CNFT Signature", sig);
      return "Success";
    } catch (error) {
      console.error("Error minting CNFT", error);
      return "Failure";
    }
    };

  const contextProvider = {
    web3Auth,
    provider,
    userData,
    isLoading,
    isLoggedIn,
    connection,
    login,
    logout,
    reconnect,
    getUserInfo,
    authenticateUser,
    getAccounts,
    getBalance,
    sendTransaction,
    signMessage,
    registerUser,
    updateUserData,
    mintCnftUmi,
  };

  return (
    <Web3AuthContext.Provider value={contextProvider}>
      {children}
    </Web3AuthContext.Provider>
  );
};

// Web3AuthProvider.defaultProps = {
//   children: null,
//   // web3AuthNetwork: "mainnet",
//   // chain: "mainnet",
// };